import { createMachine } from "xstate";

import context from "./context";
import * as actions from "./actions";
import * as services from "./services";
import * as guards from "./guards";
import PaymentStatus from "src/services/altai/entities/PaymentStatus";
import { ForgotPasswordProps } from "src/validations/forgotPassword";
import { ResetPasswordProps } from "src/validations/resetPassword";
import CreateProjectResponse from "src/services/altai/entities/CreateProjectResponse";
import AgencyUserHomePageDetails from "src/services/altai/entities/homePage/AgencyUserHomePageDetails";
import ArtistHomePageDetails from "src/services/altai/entities/homePage/ArtistHomePageDetails";
import CdHomePageDetails from "src/services/altai/entities/homePage/CdHomePageDetails";
import CdCommentNotification from "src/services/altai/entities/homePage/CdCommentNotification";

export type UserType =
  | "Inactive"
  | "CastingDirector"
  | "Agent"
  | "Artist"
  | "Altai"
  | null;

// Error code reference https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#error-codes_8
export interface AuthContext {
  accessToken: string | null;
  userType: UserType;
  firebaseId: string | null;
  username: string | null;
  isTeamAdmin: boolean;
  errorCode:
    | "auth/wrong-password"
    | "auth/user-not-found"
    | "auth/too-many-requests"
    | null;
}

export interface AppContext {
  auth: AuthContext;
  passwordResetUserEmail: string;
  paymentStatus: PaymentStatus | null;
  forgotPassword: ForgotPasswordProps;
  resetPassword: ResetPasswordProps;
  messagingUserToken: string | null;
  agencyUserDetails: AgencyUserHomePageDetails | null;
  artistHomePageDetails: ArtistHomePageDetails | null;
  cdHomePageDetails: CdHomePageDetails | null;
  cdCommentNotifications: CdCommentNotification[] | null;
  userDetailsLoaded: boolean;
  isFirstDayOnAltai: boolean | null;
  userFirstName: string;
  lastName: string;
  customToken: string | null;
  routerPath: string;
}

// TODO - Valdiate how to declare a tag as a type instead
export const AppTag = {
  loading: "loading",
};

export type AppEvent =
  // General & User related events
  | {
      type: "AUTH_AUTHENTICATED";
      accessToken: AuthContext["accessToken"];
      userType: AuthContext["userType"];
      firebaseId: AuthContext["firebaseId"];
      isTeamAdmin: AuthContext["isTeamAdmin"];
      username: AuthContext["username"];
    }
  | {
      type: "UPDATE_ACCESS_TOKEN";
      accessToken: AuthContext["accessToken"];
    }
  | { type: "AUTH_UNAUTHENTICATED" }
  | { type: "RESET_ROUTE_PATH" }
  | { type: "INIT_LOGIN_WITH_CUSTOM_TOKEN"; customToken: string }
  | { type: "AUTH_LOGIN_WITH_CUSTOM_TOKEN" }
  | { type: "AUTH_LOGIN"; email: string; password: string }
  | { type: "AUTH_LOGOUT" }
  | { type: "SUBMIT_FORGOT_PASSWORD"; payload: ForgotPasswordProps }
  | { type: "RESET_PASSWORD"; payload: ResetPasswordProps }
  | { type: "UPDATE_HOME_PAGE_TEXT" }
  | {
      type: "done.invoke.reset-password";
      data: CreateProjectResponse;
    }
  // Internal service related events
  | {
      type: "error.platform.auth-login";
      data: {
        code: AuthContext["errorCode"];
      };
    }
  | {
      type: "error.platform.auth-login-with-custom-token";
      data: {
        code: AuthContext["errorCode"];
      };
    }
  | { type: "done.invoke.get-payment-status"; data: PaymentStatus }
  | { type: "done.invoke.get-messaging-user-token"; data: string }
  | { type: "done.invoke.logout-message-token" }
  | {
      type: "done.invoke.get-agency-user-hp-details";
      data: AgencyUserHomePageDetails;
    }
  | {
      type: "done.invoke.get-artist-hp-details";
      data: ArtistHomePageDetails;
    }
  | {
      type: "done.invoke.get-cd-hp-details";
      data: CdHomePageDetails;
    }
  | { type: "done.invoke.get-cd-comment-notifications"; data: CdCommentNotification[] | null }

  | {
      type: "done.invoke.auth-logout";
    };

// TODO - Consider separating Auth into its own machine as the build matures
const appMachine = createMachine<AppContext, AppEvent>(
  {
    id: "App",
    type: "parallel",
    context,
    states: {
      // Handle auth state in background
      authentication: {
        initial: "init",
        states: {
          init: {
            // Parent manages child state to be either auth or unauthenticated
            invoke: {
              id: "firebase-authentication",
              src: "initiateAuthState",
            },
            // Either transition to an auth or unauth state
            on: {
              AUTH_AUTHENTICATED: ".authenticated",
              AUTH_UNAUTHENTICATED: ".unauthenticated",
              INIT_LOGIN_WITH_CUSTOM_TOKEN: ".customTokenLogin",
            },
            initial: "unknown",
            states: {
              unknown: {},
              customTokenLogin: {
                entry: [
                  "setCustonTokenUrl",
                  "clearAccessToken",
                  "clearPaymentStatus",
                ],
                initial: "logout",
                states: {
                  idle: {},
                  logout: {
                    tags: [AppTag.loading],
                    invoke: {
                      id: "auth-logout",
                      src: "initiateLogout",
                      onDone: "loginWithCustomToken",
                      onError: "loginError",
                    },
                  },
                  loginWithCustomToken: {
                    tags: [AppTag.loading],
                    invoke: {
                      id: "auth-login-with-custom-token",
                      src: "initiateLoginWithCustomToken",
                      onDone: "idle",
                      onError: "loginError",
                    },
                  },
                  loginError: {
                    entry: ["setAuthErrorCode"],
                    exit: ["clearAuthErrorCode"],
                  },
                  // TODO - Handle error
                  serverError: {},
                },
              },
              unauthenticated: {
                entry: ["clearAccessToken", "clearPaymentStatus"],
                on: {
                  AUTH_LOGIN: ".login",
                  RESET_ROUTE_PATH: { actions: ["resetRoutePath"] },
                },
                // Nested state to handle the login process
                initial: "idle",
                states: {
                  idle: {},
                  login: {
                    tags: [AppTag.loading],
                    invoke: {
                      id: "auth-login",
                      src: "initiateLogin",
                      onDone: "idle",
                      onError: "loginError",
                    },
                  },
                  loginError: {
                    entry: ["setAuthErrorCode"],
                    exit: ["clearAuthErrorCode"],
                    on: { AUTH_LOGIN: "login" },
                  },
                  // TODO - Handle error
                  serverError: {},
                },
              },
              authenticated: {
                entry: ["setAccessToken"],
                on: {
                  AUTH_LOGOUT: ".logout",
                  UPDATE_ACCESS_TOKEN: ".updateToken",
                },
                // Nested state to handle logout process
                initial: "init",
                states: {
                  init: {
                    always: [
                      {
                        target: "paymentValidation",
                        cond: "hasPaymentStatus",
                      },
                      { target: "getPaymentStatus" },
                    ],
                  },
                  paymentValidation: {
                    always: [
                      {
                        target: "initGetMessagingToken",
                        cond: "validateBillingInformation",
                      },
                      {
                        target: "idle",
                        cond: "isArtistFirstLoginAfterDowngrade",
                        actions: ["showAtristPricingTable"],
                      },
                      { target: "idle", actions: ["showAccountPaused"] },
                    ],
                  },
                  getPaymentStatus: {
                    tags: [AppTag.loading],
                    invoke: {
                      id: "get-payment-status",
                      src: "getPaymentStatus",
                      onDone: {
                        target: "paymentValidation",
                        actions: ["setPaymentStatus"],
                      },
                      onError: "error",
                    },
                  },
                  initGetHomePageDetails: {
                    entry: ["clearHomePageText"],
                    always: [
                      {
                        target: "getAgencyUserHomePageDetails",
                        cond: "isAgencyUser",
                      },
                      {
                        target: "getArtistHomePageDetails",
                        cond: "isArtist",
                      },
                      {
                        target: "getCdHomePageDetails",
                        cond: "isCd",
                      },
                      { target: "idle" },
                    ],
                  },
                  getAgencyUserHomePageDetails: {
                    tags: [AppTag.loading],
                    invoke: {
                      id: "get-agency-user-hp-details",
                      src: "getAgencyUserHomePageDetails",
                      onDone: {
                        target: "idle",
                        actions: ["setAgencyUserHomePageDetails"],
                      },
                      onError: "error",
                    },
                  },
                  getArtistHomePageDetails: {
                    tags: [AppTag.loading],
                    invoke: {
                      id: "get-artist-hp-details",
                      src: "getArtistHomePageDetails",
                      onDone: {
                        target: "idle",
                        actions: ["setArtistHomePageDetails"],
                      },
                      onError: "error",
                    },
                  },
                  getCdHomePageDetails: {
                    tags: [AppTag.loading],
                    invoke: {
                      id: "get-cd-hp-details",
                      src: "getCdHomePageDetails",
                      onDone: {
                        target: "getCdCommentNotifications",
                        actions: ["setCdHomePageDetails"],
                      },
                      onError: "error",
                    },
                  },
                  getCdCommentNotifications: {
                    tags: [AppTag.loading],
                    invoke: {
                      id: "get-cd-comment-notifications",
                      src: "getCdCommentNotifications",
                      onDone: {
                        target: "idle",
                        actions: ["setCdCommentNotifications"],
                      },
                      onError: "error",
                    },
                  },
                  initGetMessagingToken: {
                    always: [
                      {
                        target: "getMessagingToken",
                        cond: "userValidityForMessaging",
                      },
                      { target: "initGetHomePageDetails" },
                    ],
                  },
                  getMessagingToken: {
                    invoke: {
                      id: "get-messaging-user-token",
                      src: "getMessagingUserToken",
                      onDone: {
                        target: "initGetHomePageDetails",
                        actions: ["setMessagingUserToken"],
                      },
                      onError: "error",
                    },
                  },
                  idle: {
                    on: {
                      UPDATE_HOME_PAGE_TEXT: "getPaymentStatus",
                    },
                  },
                  initLogout: {
                    always: [
                      {
                        target: "logoutMessageToken",
                        cond: "userValidityForMessaging",
                      },
                      { target: "logout" },
                    ],
                  },
                  logoutMessageToken: {
                    tags: [AppTag.loading],
                    invoke: {
                      id: "logout-message-token",
                      src: "logoutMessageToken",
                      onDone: "logout",
                      onError: "error",
                    },
                  },
                  logout: {
                    tags: [AppTag.loading],
                    invoke: {
                      id: "auth-logout",
                      src: "initiateLogout",
                      onDone: {
                        target: "idle",
                        actions: ["clearHomePageText"],
                      },
                      onError: "error",
                    },
                  },
                  updateToken: {
                    entry: ["updateAccessToken"],
                  },
                  // TODO - Handle error
                  error: {},
                },
              },
            },
          },
        },
      },
      // Handle app state
      application: {
        initial: "idle",
        states: {
          idle: {},
        },
      },
      forgotPassword: {
        initial: "init",
        states: {
          init: {
            on: {
              SUBMIT_FORGOT_PASSWORD: "submit",
            },
          },
          submit: {
            tags: [AppTag.loading],
            entry: ["setForgotPasswordValues"],
            invoke: {
              id: "forgot-password",
              src: "forgotPassword",
              onDone: "success",
              onError: [
                {
                  target: "init",
                },
                { target: "serverError" },
              ],
            },
          },
          success: {
            entry: ["notifyComplete"],
          },
          serverError: {},
        },
      },
      resetPassword: {
        initial: "init",
        states: {
          init: {
            on: {
              RESET_PASSWORD: "submit",
            },
          },
          submit: {
            tags: [AppTag.loading],
            entry: ["setResetPasswordValues"],
            invoke: {
              id: "reset-password",
              src: "resetPassword",
              onDone: {
                target: "init",
                actions: ["setPasswordResetUserEmail", "notifyComplete"],
              },
              onError: [
                {
                  target: "init",
                },
                { target: "serverError" },
              ],
            },
          },
          serverError: {},
        },
      },
    },
  },
  {
    actions,
    services,
    guards,
  }
);

export type AppMachine = typeof appMachine;

export default appMachine;
