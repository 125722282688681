import {
  getAuth,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  User,
} from "firebase/auth";
import PaymentStatus from "src/services/altai/entities/PaymentStatus";

import firebase from "src/services/firebase";
import { AppContext, AppEvent } from "..";
import * as altai from "src/services/altai";
import CreateProjectResponse from "src/services/altai/entities/CreateProjectResponse";
import AgencyUserHomePageDetails from "src/services/altai/entities/homePage/AgencyUserHomePageDetails";
import ArtistHomePageDetails from "src/services/altai/entities/homePage/ArtistHomePageDetails";
import CdHomePageDetails from "src/services/altai/entities/homePage/CdHomePageDetails";
import CdCommentNotification from "src/services/altai/entities/homePage/CdCommentNotification";

//***************************************** */
// Initiate login with firebase
//***************************************** */
export const initiateLogin = async (
  ctx: AppContext,
  evt: AppEvent
): Promise<any> => {
  if (evt.type !== "AUTH_LOGIN") return;
  const app = firebase();
  const auth = getAuth(app);
  // Note - no need to return response, auth state will update app state once authenication is successful
  await signInWithEmailAndPassword(auth, evt.email.trim(), evt.password.trim());
};

//***************************************** */
// Initiate login with firebase
//***************************************** */
export const initiateLoginWithCustomToken = async (
  ctx: AppContext,
  evt: AppEvent
): Promise<any> => {
  if (ctx.customToken === null || ctx.customToken === "") return;
  const app = firebase();
  const auth = getAuth(app);
  // Note - no need to return response, auth state will update app state once authenication is successful
  await signInWithCustomToken(auth, ctx.customToken!);
};

//***************************************** */
// Manage user logout
//***************************************** */
export const initiateLogout = async (
  ctx: AppContext,
  evt: AppEvent
): Promise<void> => {
  if (
    evt.type !== "AUTH_LOGOUT" &&
    evt.type !== "INIT_LOGIN_WITH_CUSTOM_TOKEN" &&
    evt.type !== "done.invoke.logout-message-token"
  )
    return;
  const app = firebase();
  const auth = getAuth(app);
  auth.signOut();
};

//***************************************** */
// TODO - Research typing callback and receive
// Callback to manage application state
//***************************************** */
export const initiateAuthState =
  (ctx: AppContext, evt: AppEvent) => (callback: any) => {
    const app = firebase();
    const auth = getAuth(app);

    // Retrieve token (Not ideal, shou)
    const updateAppState = async (user: User): Promise<void> => {
      const userData = await user.getIdTokenResult();
      const accessToken = userData.token;
      let userType = userData.claims["user_type"];
      const targetUserType = userData.claims["target_user_type"];
      const username = userData.claims["name"];
      const isTeamAdmin = userData.claims[
        "company_admin"
      ] as unknown as boolean;
      const firebaseId = user.uid;

      if (targetUserType) {
        userType = targetUserType;
      }

      callback({
        type: "AUTH_AUTHENTICATED",
        accessToken,
        userType,
        firebaseId,
        isTeamAdmin,
        username,
      });
    };

    // Initiate Listener
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser === null) {
        callback({ type: "AUTH_UNAUTHENTICATED" });
        return;
      }
      // Async callback to appState
      updateAppState(authUser);
    });

    // Garbage collection
    return () => unsubscribe();
  };

//***************************************** */
// Get payment status
//***************************************** */
export const getPaymentStatus = (): Promise<PaymentStatus> => {
  return altai.getPaymentStatus();
};

//***************************************** */
// Get Login User Home Page Info
//***************************************** */
export const getAgencyUserHomePageDetails =
  (): Promise<AgencyUserHomePageDetails> => {
    const dateNow = new Date();
    const dateStart = new Date(
      dateNow.getFullYear(),
      dateNow.getMonth(),
      dateNow.getDate(),
      0,
      0,
      0
    ).toISOString();
    const dateEnd = new Date(
      dateNow.getFullYear(),
      dateNow.getMonth(),
      dateNow.getDate(),
      23,
      59,
      59
    ).toISOString();
    return altai.getAgencyUserHomePageDetails(dateStart, dateEnd);
  };

export const getArtistHomePageDetails = (): Promise<ArtistHomePageDetails> => {
  const dateNow = new Date();
  const dateStart = new Date(
    dateNow.getFullYear(),
    dateNow.getMonth(),
    dateNow.getDate(),
    0,
    0,
    0
  ).toISOString();
  return altai.getArtistHomePageDetails(dateStart, null);
};

export const getCdHomePageDetails = (): Promise<CdHomePageDetails> => {
  const dateNow = new Date();
  const dateStart = new Date(
    dateNow.getFullYear(),
    dateNow.getMonth(),
    dateNow.getDate(),
    0,
    0,
    0
  ).toISOString();
  return altai.getCdHomePageDetails(dateStart, null);
};

export const getCdCommentNotifications = (): Promise<CdCommentNotification[] | null> => {
  return altai.getCdCommentNotifications();
};

//***************************************** */
// Reset Password
//***************************************** */

export const forgotPassword = async (
  ctx: AppContext
): Promise<CreateProjectResponse> => {
  return await altai.forgotPassword(ctx.forgotPassword);
};

export const resetPassword = async (
  ctx: AppContext
): Promise<CreateProjectResponse> => {
  return await altai.resetPassword(ctx.resetPassword);
};

//***************************************** */
// Messaging User Token
//***************************************** */

export const getMessagingUserToken = async (
  ctx: AppContext
): Promise<string> => {
  return await altai.getMessagingUserToken();
};

export const logoutMessageToken = async (
  ctx: AppContext
): Promise<CreateProjectResponse> => {
  return await altai.revokeMessagingUserToken();
};
